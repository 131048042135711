import { Card, TextField } from "@mui/material";
import {
  forwardRef,
  KeyboardEvent,
  MutableRefObject,
  useImperativeHandle,
  useRef,
} from "react";

export interface ISearchBarRef {
  getSearchValue: () => string;
}

interface IProps {
  onSearch?: () => void;
}

const SearchBar = forwardRef<ISearchBarRef, IProps>((props, ref) => {
  const inputRef = useRef() as MutableRefObject<HTMLInputElement>;

  const handleKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key.toLowerCase() === "enter" && props.onSearch) {
      props.onSearch();
    }
  };

  useImperativeHandle(
    ref,
    () => ({
      getSearchValue: () => {
        return inputRef.current.value;
      },
    }),
    []
  );

  return (
    <Card
      sx={{
        p: 3,
        mb: 3,
      }}
    >
      <TextField
        inputRef={inputRef}
        label="Search"
        type="search"
        fullWidth
        onKeyUp={handleKeyUp}
      />
    </Card>
  );
});

export default SearchBar;
