import { Box, Divider, Typography } from "@mui/material";
import { ReactNode } from "react";

interface IProps {
  children?: ReactNode;
  title: string;
}

const Heading = ({ children, title }: IProps) => (
  <Box>
    <Box display="flex" alignItems="center">
      <Box>
        <Typography variant="h4" component="p" fontWeight="bold">
          {title}
        </Typography>
      </Box>
      <Box ml="auto">{children}</Box>
    </Box>
    <Box my="20px">
      <Divider />
    </Box>
  </Box>
);

export default Heading;
