import {ReactElement, ReactNode, useEffect, useState} from "react";
import {Container, styled} from "@mui/material";

// Components
import Header from "../components/Header/Header";
import Sider from '../components/Sider/Sider'
import {useDispatch} from "react-redux";
import {getProfile} from "../redux/actions/userActions";

// Styled
const StyledRoot = styled("main")``
const BodyWrapper = styled('div')`
    ${({theme}) => theme.breakpoints.up('md')} {
      margin-left: 240px;
    }
`

const ContentWrapper = styled('div')`
  padding: ${({theme}) => theme.spacing(2)} 0;
`

interface IProps {
    children: ReactNode
}

const MainLayout = ({children}: IProps): ReactElement => {
    const dispatch = useDispatch()
    const [drawerVisible, setDrawerVisible] = useState<boolean>(false);

    const handleDrawerToggle = () => setDrawerVisible(!drawerVisible)

    useEffect(() => {
        dispatch(getProfile())
    }, [])

    return (
        <StyledRoot>
            <Sider visible={drawerVisible} onClose={handleDrawerToggle}/>
            <BodyWrapper>
                <Header onOpen={handleDrawerToggle}/>
                <ContentWrapper>
                    <Container>
                        {children}
                    </Container>
                </ContentWrapper>
            </BodyWrapper>
        </StyledRoot>
    )
}

export default MainLayout
