import {applyMiddleware, createStore} from "redux";
import {composeWithDevTools} from "redux-devtools-extension";
import thunk from 'redux-thunk'
import {persistStore, persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import reducer from "./reducer";
import {UserReducerType} from "./reducer/userReducer";
import {ProductStateType} from "./reducer/productReducer";
import {LicenseStateType} from "./reducer/licenseReducer";

const middlewares = [thunk]

const persistConfig = {
    key: 'root',
    blacklist: ['products', 'licenses'],
    storage,
}

export interface RootState {
    user: UserReducerType,
    products: ProductStateType,
    licenses: LicenseStateType
}

const persistedReducer = persistReducer(persistConfig, reducer)

export const store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(...middlewares)));
export const persistor = persistStore(store)

