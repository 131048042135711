import MainLayout from "./layout/MainLayout";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AuthRoute from "./utils/AuthRoute";

// Views
import DashboardView from "./views/DashboardView";
import ProductsView from "./views/ProductsView";
import KeysView from "./views/KeysView";
import ProfileView from "./views/ProfileView";
import LoginView from "./views/LoginView";
import LogoutView from "./views/LogoutView";
import LicenseView from "./views/LicenseView";

const App = () => (
  <BrowserRouter>
    <Routes>
      <Route
        path="/dashboard"
        element={
          <AuthRoute>
            <DashboardView />
          </AuthRoute>
        }
      />
      <Route
        path="/products"
        element={
          <AuthRoute>
            <ProductsView />
          </AuthRoute>
        }
      />
      <Route
        path="/keys"
        element={
          <AuthRoute>
            <KeysView />
          </AuthRoute>
        }
      />
      <Route
        path="/profile"
        element={
          <AuthRoute>
            <ProfileView />
          </AuthRoute>
        }
      />
      <Route path="/login" element={<LoginView />} />
      <Route
        path="/logout"
        element={
          <AuthRoute>
            <LogoutView />
          </AuthRoute>
        }
      />
      <Route path="/" element={<LicenseView />} />
    </Routes>
  </BrowserRouter>
);

export default App;
