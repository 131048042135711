import {Product, ProductsListResponse} from "../../interface/product";
import {ApiError} from "../../interface/error";
import {ProductActions, ProductActionTypes} from "../types/productTypes";

export type ProductStateType = {
    newProduct: {
        data: null | Product,
        isLoading: boolean;
        error: null | ApiError;
    };
    products: {
        data: null | ProductsListResponse;
        isLoading: boolean;
        error: null | ApiError
    }
}

const ProductState: ProductStateType = {
    newProduct: {
        data: null,
        isLoading: false,
        error: null
    },
    products: {
        data: null,
        isLoading: false,
        error: null
    }
}

const ProductReducer = (state: ProductStateType = ProductState, action: ProductActions) => {
    switch (action.type) {
        case ProductActionTypes.GET_PRODUCTS_LIST_REQUEST: {
            return {
                ...state,
                products: {
                    data: null,
                    isLoading: true,
                    error: null
                }
            }
        }
        case ProductActionTypes.GET_PRODUCTS_LIST_SUCCESS: {
            return {
                ...state,
                products: {
                    ...state.products,
                    data: action.payload,
                    isLoading: false
                }
            }
        }
        case ProductActionTypes.GET_PRODUCTS_LIST_FAILED: {
            return {
                ...state,
                products: {
                    ...state.products,
                    data: null,
                    isLoading: false,
                    error: action.payload
                }
            }
        }
        case ProductActionTypes.NEW_PRODUCT_REQUEST: {
            return {
                ...state,
                newProduct: {
                    ...state.newProduct,
                    isLoading: true,
                    error: null
                }
            }
        }
        case ProductActionTypes.NEW_PRODUCT_SUCCESS: {
            return {
                ...state,
                newProduct: {
                    ...state.newProduct,
                    data: action.payload,
                    isLoading: false
                }
            }
        }
        case ProductActionTypes.NEW_PRODUCT_FAILED: {
            return {
                ...state,
                newProduct: {
                    ...state.newProduct,
                    isLoading: false,
                    data: null,
                    error: action.payload
                }
            }
        }
        case ProductActionTypes.REMOVE_PRODUCT_REQUEST: {
            return {
                ...state,
                products: {
                    ...state.products,
                    data: null,
                    isLoading: true,
                }
            }
        }
        case ProductActionTypes.REMOVE_PRODUCT_SUCCESS: {
            return {
                ...state,
                products: {
                    ...state.products,
                    isLoading: false
                }
            }
        }
        case ProductActionTypes.REMOVE_PRODUCT_FAILED: {
            return {
                ...state,
                products: {
                    ...state.products,
                    data: null,
                    isLoading: null,
                    error: action.payload
                }
            }
        }
        case ProductActionTypes.CLEAN_NEW_PRODUCT: {
            return {
                ...state,
                newProduct: {
                    ...state.newProduct,
                    isLoading: false,
                    error: null,
                    data: null
                }
            }
        }
        default: {
            return state
        }
    }
}

export default ProductReducer
