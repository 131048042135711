import { Typography } from "@mui/material";
import { ReactElement } from "react";
import Heading from "../components/Heading/Heading";
import MainLayout from "../layout/MainLayout";

const DashboardView = (): ReactElement => (
  <MainLayout>
    <Heading title="Dashboard" />
    <Typography component="h2" variant="h5">
      Work In Progress
    </Typography>
  </MainLayout>
);

export default DashboardView;
