import {useDispatch} from "react-redux";
import {ReactElement, useEffect} from "react";
import {userLogout} from "../redux/actions/userActions";

const LogoutView = (): ReactElement => {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(userLogout())
    }, [])

    return (
        <></>
    )
}

export default LogoutView
