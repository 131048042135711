import {Container, styled, Box, Avatar, Typography, FormControl, TextField, Button, Alert} from "@mui/material";
import {Lock} from '@mui/icons-material'
import {useDispatch, useSelector} from "react-redux";
import {cleanUserError, userLogin} from "../redux/actions/userActions";
import {useForm, SubmitHandler} from "react-hook-form";
import {Navigate} from "react-router-dom";
import {RootState} from "../redux/store";
import Loader from "../components/Loader/Loader";

const StyledBox = styled(Box)`
  margin-top: ${({theme}) => theme.spacing(8)};
  display: flex;
  flex-direction: column;
  align-items: center;
`

const StyledAvatar = styled(Avatar)`
  margin-bottom: ${({theme}) => theme.spacing(2)};
  background: ${({theme}) => theme.palette.primary.main};
`

const StyledForm = styled('form')`
  width: 100%;

  & > * {
    margin-bottom: ${({theme}) => theme.spacing(2)};

    :last-child {
      margin-bottom: 0;
    }
  }

`

interface IForm {
    email: string;
    password: string;
}

const LoginView = () => {
    const dispatch = useDispatch()
    const state = useSelector((state: RootState) => state.user)
    const {register, handleSubmit} = useForm<IForm>()

    const handleLogin: SubmitHandler<IForm> = (data) => {
        dispatch(cleanUserError());
        dispatch(userLogin(data.email, data.password))
    }

    if(state.data && state.isAuthenticated) {
        return <Navigate to="/dashboard" replace/>
    }

    return (
        <Container maxWidth="xs">
            <StyledBox>
                <StyledAvatar>
                    <Lock/>
                </StyledAvatar>
                <Typography component="h1" variant="h5" mb={6}>
                    Sign In
                </Typography>
                {state.isLoading && <Loader/>}
                {!state.isLoading &&
                    <StyledForm onSubmit={handleSubmit(handleLogin)}>
                        <Box>
                            <TextField label="Email Address" required fullWidth {...register('email')}/>
                        </Box>
                        <Box>
                            <TextField label="Password" type="password" required fullWidth {...register('password')}/>
                        </Box>
                        <Box>
                            <Button type="submit" variant="contained" fullWidth>Sign In</Button>
                        </Box>
                        {state.error &&
                            <Box>
                                <Alert severity="error">{state.error.message}</Alert>
                            </Box>
                        }
                    </StyledForm>
                }
            </StyledBox>
        </Container>
    )
}

export default LoginView
