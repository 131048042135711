import { ApiError } from "../../interface/error";
import { Product } from "../../interface/product";
import { License, LicenseListResponse } from "../../interface/license";

export enum LicenseActionType {
  NEW_LICENSE_REQUEST = "NEW_LICENSE_REQUEST",
  NEW_LICENSE_SUCCESS = "NEW_LICENSE_SUCCESS",
  NEW_LICENSE_FAILED = "NEW_LICENSE_FAILED",
  GET_PRODUCTS_REQUEST = "GET_PRODUCTS_REQUEST",
  GET_PRODUCTS_SUCCESS = "GET_PRODUCTS_SUCCESS",
  GET_PRODUCTS_FAILED = "GET_PRODUCTS_FAILED",
  GET_LICENSE_REQUEST = "GET_LICENSE_REQUEST",
  GET_LICENSE_SUCCESS = "GET_LICENSE_SUCCESS",
  GET_LICENSE_FAILED = "GET_LICENSE_FAILED",
  LICENSE_CLEAN_REQUEST = "LICENSE_CLEAN_REQUEST",
  LICENSE_CLEAN_SUCCESS = "LICENSE_CLEAN_SUCCESS",
  LICENSE_CLEAN_FAILED = "LICENSE_CLEAN_FAILED",
  LICENSE_LIST_REQUEST = "LICENSE_LIST_REQUEST",
  LICENSE_LIST_SUCCESS = "LICENSE_LIST_SUCCESS",
  LICENSE_LIST_FAILED = "LICENSE_LIST_FAILED",
  LICENSE_BAN_REQUEST = "LICENSE_BAN_REQUEST",
  LICENSE_BAN_SUCCESS = "LICENSE_BAN_SUCCESS",
  LICENSE_BAN_FAILED = "LICENSE_BAN_FAILED",
  LICENSE_UNLOCK_REQUEST = "LICENSE_UNLOCK_REQUEST",
  LICENSE_UNLOCK_SUCCESS = "LICENSE_UNLOCK_SUCCESS",
  LICENSE_UNLOCK_FAILED = "LICENSE_UNLOCK_FAILED",
}

interface newLicenseRequest {
  type: LicenseActionType.NEW_LICENSE_REQUEST;
}

interface newLicenseSuccess {
  type: LicenseActionType.NEW_LICENSE_SUCCESS;
  payload: string[];
}

interface newLicenseFailed {
  type: LicenseActionType.NEW_LICENSE_FAILED;
  payload: ApiError;
}

interface getProductsRequest {
  type: LicenseActionType.GET_PRODUCTS_REQUEST;
}

interface getProductsSuccess {
  type: LicenseActionType.GET_PRODUCTS_SUCCESS;
  payload: Product[];
}

interface getProductsFailed {
  type: LicenseActionType.GET_PRODUCTS_FAILED;
  payload: ApiError;
}

interface getLicenseRequest {
  type: LicenseActionType.GET_LICENSE_REQUEST;
}

interface getLicenseSuccess {
  type: LicenseActionType.GET_LICENSE_SUCCESS;
  payload: License;
}

interface getLicenseFailed {
  type: LicenseActionType.GET_LICENSE_FAILED;
  payload: ApiError;
}

interface licenseCleanRequest {
  type: LicenseActionType.LICENSE_CLEAN_REQUEST;
}

interface licenseCleanSuccess {
  type: LicenseActionType.LICENSE_CLEAN_SUCCESS;
  payload: License;
}

interface licenseCleanFailed {
  type: LicenseActionType.LICENSE_CLEAN_FAILED;
  payload: ApiError;
}

interface licenseListRequest {
  type: LicenseActionType.LICENSE_LIST_REQUEST;
}

interface licenseListSuccess {
  type: LicenseActionType.LICENSE_LIST_SUCCESS;
  payload: LicenseListResponse;
}

interface licenseListFailed {
  type: LicenseActionType.LICENSE_LIST_FAILED;
  payload: ApiError;
}

interface licenseBanRequest {
  type: LicenseActionType.LICENSE_BAN_REQUEST;
}

interface licenseBanSuccess {
  type: LicenseActionType.LICENSE_BAN_SUCCESS;
}

interface licenseBanFailed {
  type: LicenseActionType.LICENSE_BAN_FAILED;
  payload: ApiError;
}

interface licenseUnlockRequest {
  type: LicenseActionType.LICENSE_UNLOCK_REQUEST;
}

interface licenseUnlockSuccess {
  type: LicenseActionType.LICENSE_UNLOCK_SUCCESS;
}

interface licenseUnlockFailed {
  type: LicenseActionType.LICENSE_UNLOCK_FAILED;
  payload: ApiError;
}

export type LicenseActions =
  | newLicenseRequest
  | newLicenseSuccess
  | newLicenseFailed
  | getProductsRequest
  | getProductsSuccess
  | getProductsFailed
  | getLicenseRequest
  | getLicenseSuccess
  | getLicenseFailed
  | licenseCleanRequest
  | licenseCleanSuccess
  | licenseCleanFailed
  | licenseListRequest
  | licenseListSuccess
  | licenseListFailed
  | licenseBanRequest
  | licenseBanSuccess
  | licenseBanFailed
  | licenseUnlockRequest
  | licenseUnlockSuccess
  | licenseUnlockFailed;
