import {
  Box,
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import {
  Dashboard,
  FormatListBulleted,
  VpnKey,
  Person,
  Logout,
} from "@mui/icons-material";
import { styled } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import { Dispatch, SetStateAction } from "react";

// Styled
const StyledDrawer = styled(Drawer)`
  .MuiPaper-root {
    width: 240px;
  }
`;

const LogoWrapper = styled(Box)`
  min-height: 64px;
  padding: 0 ${({ theme }) => theme.spacing(2)};
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.palette.primary.contrastText};
  background-color: ${({ theme }) => theme.palette.primary.dark};
`;

const StyledList = styled(List)`
  padding-top: 0;
`;

interface IProps {
  visible: boolean;
  onClose: Dispatch<SetStateAction<void>>;
}

const Sider = ({ visible, onClose }: IProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <StyledDrawer
      open={isMobile ? visible : false}
      variant={isMobile ? "temporary" : "permanent"}
      onClose={() => {
        onClose();
      }}
    >
      <LogoWrapper>
        <Typography component="h1" fontWeight="bold">
          TONESIL Audio
        </Typography>
      </LogoWrapper>
      <StyledList>
        <ListItemButton component="a" href="/dashboard">
          <ListItemIcon>
            <Dashboard />
          </ListItemIcon>
          <ListItemText>Dashboard</ListItemText>
        </ListItemButton>
        <ListItemButton component="a" href="/products">
          <ListItemIcon>
            <FormatListBulleted />
          </ListItemIcon>
          <ListItemText>Products</ListItemText>
        </ListItemButton>
        <ListItemButton component="a" href="/keys">
          <ListItemIcon>
            <VpnKey />
          </ListItemIcon>
          <ListItemText>Keys</ListItemText>
        </ListItemButton>
        <ListItemButton component="a" href="/profile">
          <ListItemIcon>
            <Person />
          </ListItemIcon>
          <ListItemText>Profile</ListItemText>
        </ListItemButton>
        <ListItemButton component="a" href="/logout">
          <ListItemIcon>
            <Logout />
          </ListItemIcon>
          <ListItemText>Logout</ListItemText>
        </ListItemButton>
      </StyledList>
    </StyledDrawer>
  );
};

export default Sider;
