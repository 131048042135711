import {useState} from "react";

const useModalState = (init = false) => {

    const [isOpen, setIsOpen] = useState(init);

    const onOpen = () => setIsOpen(true);
    const onClose = () => setIsOpen(false);
    const onToggle = () => setIsOpen(!isOpen);

    return {
        isOpen,
        onOpen,
        onClose,
        onToggle
    }

}

export default useModalState
