import { AxiosError } from "axios";
import { ApiError } from "../../interface/error";
import { Dispatch } from "redux";
import { LicenseActions, LicenseActionType } from "../types/licenseTypes";
import api from "../../utils/api";
import { Product } from "../../interface/product";
import { License, LicenseListResponse } from "../../interface/license";

export const getProducts = () => async (dispatch: Dispatch<LicenseActions>) => {
  dispatch({
    type: LicenseActionType.GET_PRODUCTS_REQUEST,
  });

  try {
    const { data } = await api.get<Product[]>("/products/all");
    dispatch({
      type: LicenseActionType.GET_PRODUCTS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    const error = err as AxiosError<ApiError>;
    if (error.response) {
      dispatch({
        type: LicenseActionType.GET_PRODUCTS_FAILED,
        payload: {
          message: error.response?.data.message,
          code: error.response?.data.code,
        },
      });
    }
  }
};

export const createNewLicense =
  (productId: string, quantity: number) =>
  async (dispatch: Dispatch<LicenseActions>) => {
    dispatch({
      type: LicenseActionType.NEW_LICENSE_REQUEST,
    });

    try {
      const { data } = await api.post<string[]>("/licenses", {
        productId,
        quantity,
      });
      dispatch({
        type: LicenseActionType.NEW_LICENSE_SUCCESS,
        payload: data,
      });
    } catch (err) {
      const error = err as AxiosError<ApiError>;
      if (error.response) {
        dispatch({
          type: LicenseActionType.NEW_LICENSE_FAILED,
          payload: {
            message: error.response?.data.message,
            code: error.response?.data.code,
          },
        });
      }
    }
  };

export const getLicense =
  (key: string) => async (dispatch: Dispatch<LicenseActions>) => {
    dispatch({
      type: LicenseActionType.GET_LICENSE_REQUEST,
    });

    try {
      const { data } = await api.get<License>(`/licenses/${key}`);
      dispatch({
        type: LicenseActionType.GET_LICENSE_SUCCESS,
        payload: data,
      });
    } catch (err) {
      const error = err as AxiosError<ApiError>;
      if (error.response) {
        dispatch({
          type: LicenseActionType.GET_LICENSE_FAILED,
          payload: {
            message: error.response.data.message,
            code: error.response.data.code,
          },
        });
      }
    }
  };

export const clearLicense =
  (key: string) => async (dispatch: Dispatch<LicenseActions>) => {
    dispatch({
      type: LicenseActionType.LICENSE_CLEAN_REQUEST,
    });

    try {
      const { data } = await api.patch<License>(`/licenses/${key}`);
      dispatch({
        type: LicenseActionType.LICENSE_CLEAN_SUCCESS,
        payload: data,
      });
    } catch (err) {
      const error = err as AxiosError<ApiError>;
      if (error.response) {
        dispatch({
          type: LicenseActionType.GET_LICENSE_FAILED,
          payload: {
            message: error.response.data.message,
            code: error.response.data.code,
          },
        });
      }
    }
  };

export const getLicenseList =
  (key?: string) => async (dispatch: Dispatch<LicenseActions>) => {
    dispatch({
      type: LicenseActionType.LICENSE_LIST_REQUEST,
    });

    try {
      const { data } = await api.get<LicenseListResponse>(
        `/licenses?key=${key ? key : ""}`
      );

      dispatch({
        type: LicenseActionType.LICENSE_LIST_SUCCESS,
        payload: data,
      });
    } catch (err) {
      const error = err as AxiosError<ApiError>;

      if (error.response) {
        dispatch({
          type: LicenseActionType.LICENSE_LIST_FAILED,
          payload: error.response.data,
        });
      }
    }
  };

export const banLicense =
  (key: string) => async (dispatch: Dispatch<LicenseActions>) => {
    dispatch({
      type: LicenseActionType.LICENSE_BAN_REQUEST,
    });

    try {
      const { data } = await api.patch<License>(`/licenses/${key}/ban`, {});

      return dispatch({
        type: LicenseActionType.LICENSE_BAN_SUCCESS,
      });
    } catch (err) {
      const error = err as AxiosError<ApiError>;

      if (error.response) {
        return dispatch({
          type: LicenseActionType.LICENSE_BAN_FAILED,
          payload: error.response.data,
        });
      }
    }
  };

export const unlockLicense =
  (key: string) => async (dispatch: Dispatch<LicenseActions>) => {
    dispatch({
      type: LicenseActionType.LICENSE_UNLOCK_REQUEST,
    });

    try {
      const { data } = await api.patch<License>(`/licenses/${key}/unlock`, {});

      return dispatch({
        type: LicenseActionType.LICENSE_UNLOCK_SUCCESS,
      });
    } catch (err) {
      const error = err as AxiosError<ApiError>;

      if (error.response) {
        return dispatch({
          type: LicenseActionType.LICENSE_UNLOCK_FAILED,
          payload: error.response.data,
        });
      }
    }
  };
