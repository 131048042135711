import { Add } from "@mui/icons-material";
import { Button, Modal } from "@mui/material";
import { MutableRefObject, ReactElement, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Heading from "../components/Heading/Heading";
import Loader from "../components/Loader/Loader";
import NewKeysModal from "../components/Modal/NewKeysModal";
import SearchBar, { ISearchBarRef } from "../components/SearchBar/SearchBar";
import CustomTable from "../components/Table/CustomTable";
import useModalState from "../hooks/useModalState";
import MainLayout from "../layout/MainLayout";
import {
  banLicense,
  getLicenseList,
  unlockLicense,
} from "../redux/actions/licenseAction";
import { RootState } from "../redux/store";

const KeysView = (): ReactElement => {
  const searchRef = useRef<ISearchBarRef>(null);

  const dispatch = useDispatch();
  const licensesState = useSelector(
    (state: RootState) => state.licenses.licenseList
  );
  const { isOpen, onOpen, onClose } = useModalState();

  const handleSearch = () => {
    if (searchRef.current) {
      console.log(searchRef.current.getSearchValue());
      dispatch(getLicenseList(searchRef.current.getSearchValue()));
    }
  };

  const handleLicenseBan = async (key: string) => {
    await dispatch(banLicense(key));
    await dispatch(getLicenseList());
  };

  const handleLicenseUnlock = async (key: string) => {
    await dispatch(unlockLicense(key));
    await dispatch(getLicenseList());
  };

  useEffect(() => {
    dispatch(getLicenseList());
  }, [dispatch]);

  return (
    <MainLayout>
      <Modal open={isOpen} onClose={onClose}>
        <NewKeysModal onClose={onClose} />
      </Modal>
      <Heading title="Keys">
        <Button variant="contained" startIcon={<Add />} onClick={onOpen}>
          New Keys
        </Button>
      </Heading>
      <SearchBar ref={searchRef} onSearch={handleSearch} />
      {licensesState.isLoading && <Loader />}
      {!licensesState.isLoading && licensesState.data && (
        <>
          <CustomTable
            data={licensesState.data}
            headings={["Key", "Product", "Blocked", "Used", "Action"]}
            onBan={handleLicenseBan}
            onUnlock={handleLicenseUnlock}
          />
        </>
      )}
    </MainLayout>
  );
};

export default KeysView;
