import { UserActions, UserActionTypes } from "../types/userTypes";
import { UserData } from "../../interface/user";
import { ApiError } from "../../interface/error";

export type UserReducerType = {
  data: UserData | null;
  isLoading: boolean;
  isAuthenticated: boolean;
  error: ApiError | null;
};

const UserReducerState: UserReducerType = {
  data: null,
  isLoading: false,
  isAuthenticated: false,
  error: null,
};

const userReducer = (
  state: UserReducerType = UserReducerState,
  action: UserActions
) => {
  switch (action.type) {
    case UserActionTypes.USER_LOGIN_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case UserActionTypes.USER_LOGIN_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isAuthenticated: true,
        data: action.payload,
      };
    }
    case UserActionTypes.USER_LOGIN_FAILED: {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    }
    case UserActionTypes.CLEAN_USER_ERROR: {
      return {
        ...state,
        error: null,
      };
    }
    case UserActionTypes.USER_LOGOUT: {
      return {
        ...UserReducerState,
      };
    }
    case UserActionTypes.USER_RESET: {
      return {
        ...UserReducerState,
      };
    }
    default: {
      return state;
    }
  }
};

export default userReducer;
