import {styled, Box, CircularProgress} from "@mui/material";

const StyledBox = styled(Box)`
  display: flex;
  justify-content: center;
  margin: ${({theme}) => theme.spacing(4)} 0;
`

const Loader = () => (
    <StyledBox>
        <CircularProgress size={75}/>
    </StyledBox>
)

export default Loader
