import {Alert, Box, Button, Grid, IconButton, Paper, styled, TextField, Typography} from "@mui/material";
import {Close} from '@mui/icons-material'
import {ChangeEvent, Dispatch, forwardRef, SetStateAction, useEffect, useState} from "react";
import {useForm, SubmitHandler} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import slugify from "slugify";
import {RootState} from "../../redux/store";
import {cleanNewProduct, createNewProduct, getProductList} from "../../redux/actions/productActions";
import Loader from "../Loader/Loader";

const Wrapper = styled(Box)`
  width: 100%;
  height: 100%;
  background: white;

  ${({theme}) => theme.breakpoints.up('md')} {
    height: auto;
    width: auto;
    background: transparent;
  }
`

const Header = styled(Box)`
  margin-bottom: ${({theme}) => theme.spacing(2)};
`

const ModalBody = styled(Paper)`
  padding: ${({theme}) => theme.spacing(2)};

  ${({theme}) => theme.breakpoints.up('md')} {
    position: fixed;
    width: 100%;
    max-width: 500px;
    top: 25%;
    left: 50%;
    background: white;
    transform: translateX(-50%);
  }
`

const StyledForm = styled('form')`
  & > * {
    margin-bottom: 20px
  }
`

interface IProps {
    onClose: Dispatch<SetStateAction<void>>
}

interface IForm {
    name: string;
    secret_code: string;
}

const NewProductModal = forwardRef<unknown, IProps>((props, ref) => {

    const dispatch = useDispatch();
    const state = useSelector((state: RootState) => state.products.newProduct);
    const {register, handleSubmit, setValue, getValues} = useForm<IForm>();
    const [slug, setSlug] = useState<string>("");

    const handleCreateNewLabel: SubmitHandler<IForm> = async (data) => {
        await dispatch(createNewProduct(data.name, data.secret_code));
        setValue('name', '');
        setValue('secret_code', '');
        await dispatch(getProductList());
    }

    const handleNameInput = (e: ChangeEvent<HTMLInputElement>) => {
        setValue('secret_code', slugify(e.target.value.toLowerCase()))
    }

    useEffect(() => {
        return () => {
            dispatch(cleanNewProduct())
        }
    }, [])

    return (
        <Wrapper>
            <ModalBody>
                <Header display="flex" alignItems="center" justifyContent="space-between">
                    <Typography fontWeight={500}>New Product</Typography>
                    <IconButton onClick={() => {
                        props.onClose()
                    }}>
                        <Close/>
                    </IconButton>
                </Header>
                {state.isLoading && <Loader/>}
                {!state.isLoading &&
                    <>
                        <StyledForm onSubmit={handleSubmit(handleCreateNewLabel)}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField label="Name" fullWidth {...register('name', {
                                        required: true,
                                        onChange: handleNameInput
                                    })}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField disabled placeholder="Secret"
                                               fullWidth {...register('secret_code', {required: true})}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button variant="contained" fullWidth type="submit">Create Product</Button>
                                </Grid>
                            </Grid>
                        </StyledForm>
                        {state.data && <Alert severity="success">Product Created</Alert>}
                        {state.error && <Alert severity="error">{state.error.message}</Alert>}
                    </>
                }
            </ModalBody>
        </Wrapper>
    )
})


export default NewProductModal
