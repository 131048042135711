import { UserData } from "../../interface/user";
import { ApiError } from "../../interface/error";

export enum UserActionTypes {
  USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST",
  USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS",
  USER_LOGIN_FAILED = "USER_LOGIN_FAILED",
  CLEAN_USER_ERROR = "CLEAN_USER_ERROR",
  USER_LOGOUT = "USER_LOGOUT",
  USER_RESET = " USER_RESET",
}

interface userLoginRequest {
  type: UserActionTypes.USER_LOGIN_REQUEST;
}

interface userLoginSuccess {
  type: UserActionTypes.USER_LOGIN_SUCCESS;
  payload: UserData;
}

interface userLoginFailed {
  type: UserActionTypes.USER_LOGIN_FAILED;
  payload: ApiError;
}

interface cleanUserError {
  type: UserActionTypes.CLEAN_USER_ERROR;
}

interface userLogout {
  type: UserActionTypes.USER_LOGOUT;
}

interface userReset {
  type: UserActionTypes.USER_RESET;
}

export type UserActions =
  | userLoginRequest
  | userLoginSuccess
  | userLoginFailed
  | cleanUserError
  | userLogout
  | userReset;
