import {ReactElement, ReactNode} from "react";
import {useSelector} from "react-redux";
import {RootState} from "../redux/store";
import {Navigate} from "react-router-dom";

interface IProps {
    children: ReactElement
}

const AuthRoute = ({children}: IProps ) => {
    const isAuth = useSelector((state: RootState) => state.user.isAuthenticated)

    if(!isAuth) {
        return <Navigate to="/login" replace/>
    }

    return children
}

export default AuthRoute
