import {Dispatch} from "redux";
import {ProductActions, ProductActionTypes} from "../types/productTypes";
import {AxiosError} from "axios";
import {ApiError} from "../../interface/error";
import api from "../../utils/api";
import {Product, ProductsListResponse} from "../../interface/product";

export const createNewProduct = (name: string, secret_code: string) => async (dispatch: Dispatch<ProductActions>) => {
    dispatch({
        type: ProductActionTypes.NEW_PRODUCT_REQUEST
    })

    try {
        const {data} = await api.post<Product>('/products', {name, secret_code})
        dispatch({
            type: ProductActionTypes.NEW_PRODUCT_SUCCESS,
            payload: data
        })

    } catch (err) {
        const error = err as AxiosError<ApiError>

        if (error.response) {
            dispatch({
                type: ProductActionTypes.NEW_PRODUCT_FAILED,
                payload: {
                    message: error.response.data.message,
                    code: error.response.data.code
                }
            })
        }
    }
}

export const getProductList = (page?: number) => async (dispatch: Dispatch<ProductActions>) => {
    dispatch({
        type: ProductActionTypes.GET_PRODUCTS_LIST_REQUEST
    })

    try {
        page = Number(page) + 1

        const {data} = await api.get<ProductsListResponse>(`/products?page=${page}`)
        dispatch({
            type: ProductActionTypes.GET_PRODUCTS_LIST_SUCCESS,
            payload: data
        })

    } catch (err) {
        const error = err as AxiosError<ApiError>

        if (error.response) {
            dispatch({
                type: ProductActionTypes.GET_PRODUCTS_LIST_FAILED,
                payload: {
                    message: error.response.data.message,
                    code: error.response.data.code
                }
            })
        }
    }
}

export const removeProduct = (id: string) => async (dispatch: Dispatch<ProductActions>) => {
    dispatch({
        type: ProductActionTypes.REMOVE_PRODUCT_REQUEST
    })

    try {
        const {data} = await api.delete<Product>(`/products/${id}`)
        dispatch({
            type: ProductActionTypes.REMOVE_PRODUCT_SUCCESS,
            payload: data
        })

    } catch (err) {
        const error = err as AxiosError<ApiError>

        if (error.response) {
            dispatch({
                type: ProductActionTypes.REMOVE_PRODUCT_FAILED,
                payload: {
                    message: error.response.data.message,
                    code: error.response.data.code
                }
            })
        }
    }
}

export const cleanNewProduct = () => (dispatch: Dispatch<ProductActions>) => {
    dispatch({
        type: ProductActionTypes.CLEAN_NEW_PRODUCT
    })
}
