import { ApiError } from "../../interface/error";
import { License, LicenseListResponse } from "../../interface/license";
import { Product } from "../../interface/product";
import { LicenseActions, LicenseActionType } from "../types/licenseTypes";

export type LicenseStateType = {
  newLicense: {
    data: null | string[];
    products: null | Product[];
    isLoading: boolean;
    error: null | ApiError;
  };
  licenseList: {
    data: null | LicenseListResponse;
    isLoading: boolean;
    error: null | ApiError;
  };
  currentLicense: {
    data: null | License;
    isLoading: boolean;
    error: null | ApiError;
  };
};

export const LicenseState: LicenseStateType = {
  newLicense: {
    data: null,
    products: null,
    isLoading: false,
    error: null,
  },
  licenseList: {
    data: null,
    isLoading: false,
    error: null,
  },
  currentLicense: {
    data: null,
    isLoading: false,
    error: null,
  },
};

const LicenseReducer = (
  state: LicenseStateType = LicenseState,
  action: LicenseActions
) => {
  switch (action.type) {
    case LicenseActionType.NEW_LICENSE_REQUEST: {
      return {
        ...state,
        newLicense: {
          ...state.newLicense,
          isLoading: true,
          error: null,
        },
      };
    }
    case LicenseActionType.NEW_LICENSE_SUCCESS: {
      return {
        ...state,
        newLicense: {
          ...state.newLicense,
          isLoading: false,
          data: action.payload,
        },
      };
    }
    case LicenseActionType.NEW_LICENSE_FAILED: {
      return {
        ...state,
        newLicense: {
          ...state.newLicense,
          data: null,
          isLoading: false,
          error: action.payload,
        },
      };
    }
    case LicenseActionType.GET_PRODUCTS_REQUEST: {
      return {
        ...state,
        newLicense: {
          ...state,
          isLoading: true,
        },
      };
    }
    case LicenseActionType.GET_PRODUCTS_SUCCESS: {
      return {
        ...state,
        newLicense: {
          ...state,
          isLoading: false,
          products: action.payload,
        },
      };
    }
    case LicenseActionType.GET_PRODUCTS_FAILED: {
      return {
        ...state,
        newLicense: {
          ...state,
          products: null,
          isLoading: false,
          error: action.payload,
        },
      };
    }
    case LicenseActionType.GET_LICENSE_REQUEST: {
      return {
        ...state,
        currentLicense: {
          ...state.currentLicense,
          isLoading: true,
          error: null,
          data: null,
        },
      };
    }
    case LicenseActionType.GET_LICENSE_SUCCESS: {
      return {
        ...state,
        currentLicense: {
          ...state.currentLicense,
          isLoading: false,
          data: action.payload,
        },
      };
    }
    case LicenseActionType.GET_LICENSE_FAILED: {
      return {
        ...state,
        currentLicense: {
          ...state.currentLicense,
          isLoading: false,
          data: null,
          error: action.payload,
        },
      };
    }
    case LicenseActionType.LICENSE_CLEAN_REQUEST: {
      return {
        ...state,
        currentLicense: {
          ...state.currentLicense,
          isLoading: true,
          error: null,
        },
      };
    }
    case LicenseActionType.LICENSE_CLEAN_SUCCESS: {
      return {
        ...state,
        currentLicense: {
          ...state.currentLicense,
          isLoading: false,
          data: action.payload,
        },
      };
    }
    case LicenseActionType.LICENSE_CLEAN_FAILED: {
      return {
        ...state,
        currentLicense: {
          ...state.currentLicense,
          isLoading: false,
          data: null,
          error: action.payload,
        },
      };
    }
    case LicenseActionType.LICENSE_LIST_REQUEST: {
      return {
        ...state,
        licenseList: {
          ...state.licenseList,
          data: null,
          isLoading: true,
          error: null,
        },
      };
    }
    case LicenseActionType.LICENSE_LIST_SUCCESS: {
      return {
        ...state,
        licenseList: {
          ...state.licenseList,
          data: action.payload,
          isLoading: false,
        },
      };
    }
    case LicenseActionType.LICENSE_LIST_FAILED: {
      return {
        ...state,
        licenseList: {
          ...state.licenseList,
          data: null,
          isLoading: false,
          error: action.payload,
        },
      };
    }
    case LicenseActionType.LICENSE_UNLOCK_REQUEST:
    case LicenseActionType.LICENSE_BAN_REQUEST: {
      return {
        ...state,
        licenseList: {
          ...state.licenseList,
          isLoading: true,
          error: null,
        },
      };
    }
    case LicenseActionType.LICENSE_UNLOCK_SUCCESS:
    case LicenseActionType.LICENSE_BAN_SUCCESS: {
      return {
        ...state,
        licenseList: {
          ...state.licenseList,
          isLoading: false,
          error: null,
        },
      };
    }
    case LicenseActionType.LICENSE_UNLOCK_FAILED:
    case LicenseActionType.LICENSE_BAN_FAILED: {
      return {
        ...state,
        licenseList: {
          ...state.licenseList,
          data: null,
          isLoading: false,
          error: action.payload,
        },
      };
    }
    default: {
      return state;
    }
  }
};

export default LicenseReducer;
