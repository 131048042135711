import {Box, Button, styled, TextField} from "@mui/material";
import Heading from "../components/Heading/Heading";
import MainLayout from "../layout/MainLayout";
import Loader from "../components/Loader/Loader";
import {useSelector} from "react-redux";
import {RootState} from "../redux/store";
import {useForm} from "react-hook-form";
import {useEffect} from "react";

const StyledForm = styled('form')`
  & > * {
    margin-bottom: ${({theme}) => theme.spacing(2)};
  }
`

interface IForm {
    email: string;
    password: string;
    password_confirm: string;
}

const ProfileView = () => {

    const state = useSelector((state: RootState) => state.user)
    const {register, setValue} = useForm<IForm>()

    useEffect(() => {
        if (state.data) {
            setValue('email', state.data.email)
        }
    }, [])

    return (
        <MainLayout>
            <Heading title="Profile"/>
            <StyledForm>
                <Box>
                    <TextField label="Email" fullWidth {...register('email', {required: true})}/>
                </Box>
                <Box>
                    <TextField label="Password" type="password" fullWidth {...register('password', {required: true})}/>
                </Box>
                <Box>
                    <TextField label="Password Confirm" type="password"
                               fullWidth {...register('password_confirm', {required: true})}/>
                </Box>
                <Box>
                    <Button variant="contained" fullWidth>Update Profile</Button>
                </Box>
            </StyledForm>
        </MainLayout>
    )
}


export default ProfileView
