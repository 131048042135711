import {Dispatch, forwardRef, SetStateAction, useEffect} from "react";
import {Box, Button, InputLabel, styled, TextField, FormControl, MenuItem, Select, Alert} from "@mui/material";
import ModalBody from "./ModalBody";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../redux/store";
import {useForm, SubmitHandler} from "react-hook-form";
import {createNewLicense, getProducts} from "../../redux/actions/licenseAction";
import Loader from "../Loader/Loader";

// Styled
const StyledForm = styled('form')`
  margin-bottom: ${({theme}) => theme.spacing(2)};

  & > * {
    margin-bottom: ${({theme}) => theme.spacing(2)};

    :last-child {
      margin-bottom: 0;
    }
  }
`

interface IProps {
    onClose: Dispatch<SetStateAction<void>>
}

interface IFrom {
    productId: string;
    quantity: number;
}

const NewKeysModal = forwardRef<unknown, IProps>((props, ref) => {

    const dispatch = useDispatch()
    const state = useSelector((state: RootState) => state.licenses.newLicense)
    const {register, handleSubmit} = useForm<IFrom>()

    const handleGenerateLicense: SubmitHandler<IFrom> = (data) => {
        dispatch(createNewLicense(data.productId, data.quantity))
    }

    const handleDownload = () => {
        if (state.data) {
            const serials = state.data.map(license => license).join("\r\n");
            const element = document.createElement('a');

            const file = new Blob([serials], {
                type: 'text/plain'
            })
            element.href = URL.createObjectURL(file);
            element.download = `License Keys`;
            document.body.appendChild(element)
            element.click();
        }
    }

    useEffect(() => {
        dispatch(getProducts())
    }, [])

    return (
        <ModalBody title="New keys" onClose={props.onClose}>
            {state.isLoading && <Loader/>}
            {!state.isLoading && !state.data &&
                <StyledForm onSubmit={handleSubmit(handleGenerateLicense)}>
                    <Box>
                        <InputLabel id="product">Product</InputLabel>
                        <Select
                            labelId="product"
                            id="product"
                            label="Product"
                            fullWidth
                            required
                            {...register('productId', {required: true})}
                        >
                            {state.products && state.products.map(product => (
                                <MenuItem key={product._id} value={product._id}>{product.name}</MenuItem>
                            ))}
                        </Select>
                    </Box>
                    <Box>
                        <TextField label="Number of keys" type="number" fullWidth
                                   required {...register('quantity', {required: true})}/>
                    </Box>
                    <Box>
                        <Button type="submit" variant="contained" fullWidth>Create Keys</Button>
                    </Box>
                </StyledForm>
            }
            {state.error && <Alert severity="error">{state.error.message}</Alert>}
            <Box display="flex" flexDirection="column">
                <Box mb={1}>
                    {state.data && <Alert severity="success">Licenses created</Alert>}
                </Box>
                {state.data && <Button variant="outlined" onClick={handleDownload}>Download licenses</Button>}
            </Box>
        </ModalBody>
    )
})


export default NewKeysModal
