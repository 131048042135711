import {combineReducers} from "redux";
import UserReducer from "./userReducer";
import ProductReducer from "./productReducer";
import LicenseReducer from "./licenseReducer";

const reducers = combineReducers({
    user: UserReducer,
    products: ProductReducer,
    licenses: LicenseReducer
})

export default reducers
