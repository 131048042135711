import { Block, Delete, Approval } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { LicenseListResponse } from "../../interface/license";

interface IProps {
  headings: string[];
  data: LicenseListResponse;
  onBan: (key: string) => void;
  onUnlock: (key: string) => void;
}

const CustomTable = ({ headings, data, onBan, onUnlock }: IProps) => (
  <TableContainer component={Card}>
    <Table>
      <TableHead>
        <TableRow>
          {headings.map((name) => (
            <TableCell key={name}>{name}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {data.docs.map((license) => (
          <TableRow key={license._id}>
            <TableCell>{license.key}</TableCell>
            <TableCell>{license.product.name}</TableCell>
            <TableCell>{license.banned ? "Yes" : "No"}</TableCell>
            <TableCell>
              {license.machine_id || license.machine_os ? "Yes" : "No"}
            </TableCell>
            <TableCell>
              <Box display="flex" gap={1}>
                {!license.banned ? (
                  <Button
                    startIcon={<Block />}
                    size="small"
                    variant="contained"
                    onClick={() => onBan(license.key)}
                    color="warning"
                  >
                    Block
                  </Button>
                ) : (
                  <Button
                    startIcon={<Approval />}
                    size="small"
                    variant="contained"
                    onClick={() => onUnlock(license.key)}
                    color="success"
                  >
                    Unlock
                  </Button>
                )}
                <Button
                  startIcon={<Delete />}
                  size="small"
                  variant="contained"
                  disabled
                >
                  Delete
                </Button>
              </Box>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

export default CustomTable;
