import { Dispatch, ReactElement, SetStateAction } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  IconButton,
} from "@mui/material";
import { Delete } from "@mui/icons-material";
import { ProductsListResponse } from "../../interface/product";

interface IProps {
  data: ProductsListResponse;
  page: number;
  onPageChange: Dispatch<SetStateAction<number>>;
  onDelete: (id: string) => void;
}

const ProductsTable = ({
  data,
  page,
  onPageChange,
  onDelete,
}: IProps): ReactElement => {
  return (
    <TableContainer component={Paper} elevation={1}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Secret</TableCell>
            <TableCell>Keys</TableCell>
            <TableCell>Activated</TableCell>
            <TableCell>Blocked</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {data.docs.map((doc) => (
            <TableRow key={doc._id}>
              <TableCell>{doc.name}</TableCell>
              <TableCell>{doc.secret_code}</TableCell>
              <TableCell>{doc.licenses.count}</TableCell>
              <TableCell>{doc.licenses.activated}</TableCell>
              <TableCell>{doc.licenses.banned}</TableCell>
              <TableCell>
                <IconButton onClick={() => onDelete(doc._id)}>
                  <Delete />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={data.totalDocs}
        page={page}
        rowsPerPage={10}
        onPageChange={(event, page) => onPageChange(page)}
      />
    </TableContainer>
  );
};

export default ProductsTable;
