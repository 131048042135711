import {
    Alert,
    Box,
    Button,
    Container,
    Paper,
    styled,
    Table,
    TableBody,
    TableContainer,
    TableRow,
    TableCell,
    TextField
} from "@mui/material";
import {SubmitHandler, useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {clearLicense, getLicense} from "../redux/actions/licenseAction";
import {RootState} from "../redux/store";
import Loader from "../components/Loader/Loader";

const PageWrapper = styled('main')`
  height: 100%;
  background: ${({theme}) => theme.palette.primary.dark};
`

const StyledContainer = styled(Container)`
  height: 100%;
`

const StyledBox = styled(Box)`
  height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const StyledPaper = styled(Paper)`
  padding: ${({theme}) => theme.spacing(2)};
`

const StyledForm = styled('form')`
  margin-bottom: ${({theme}) => theme.spacing(2)};

  & > * {
    margin-bottom: ${({theme}) => theme.spacing(2)};

    :last-child {
      margin-bottom: 0;
    }
  }
`

interface IFrom {
    serial: string
}

const LicenseView = () => {

    const dispatch = useDispatch()
    const state = useSelector((state: RootState) => state.licenses.currentLicense)
    const {register, handleSubmit} = useForm<IFrom>()

    const handleGetKey: SubmitHandler<IFrom> = (data) => {
        dispatch(getLicense(data.serial))
    }

    const handleLicenseClear = () => {
        if (state.data) {
            dispatch(clearLicense(state.data.key))
        }
    }

    return (
        <PageWrapper>
            <StyledContainer maxWidth="xs">
                <StyledBox>
                    <StyledPaper elevation={5}>
                        {state.data && !state.isLoading &&
                            <TableContainer>
                                <Table sx={{
                                    marginBottom: "16px"
                                }}>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>KEY</TableCell>
                                            <TableCell>{state.data.key}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Machine ID</TableCell>
                                            <TableCell>{state.data.machine_id || 'Empty'}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Machine OS</TableCell>
                                            <TableCell>{state.data.machine_os || 'Empty'}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                                <Button variant="contained" fullWidth onClick={handleLicenseClear}>Remove current machine</Button>
                            </TableContainer>
                        }
                        {state.isLoading && <Loader/>}
                        {!state.isLoading && !state.data &&
                            <StyledForm onSubmit={handleSubmit(handleGetKey)}>
                                <TextField label="Serial Key" placeholder="Serial key"
                                           fullWidth {...register('serial', {required: true})}/>
                                <Button type="submit" variant="contained" fullWidth>Check license</Button>
                            </StyledForm>
                        }
                        {state.error && <Alert severity="error">{state.error.message}</Alert>}
                    </StyledPaper>
                </StyledBox>
            </StyledContainer>
        </PageWrapper>
    )
}


export default LicenseView
