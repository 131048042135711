import {Dispatch, ReactElement, SetStateAction} from "react";
import {
    AppBar,
    Avatar,
    Box,
    Container,
    IconButton,
    Toolbar,
    styled,
    AppBarProps,
    useTheme,
    useMediaQuery
} from "@mui/material";
import {Menu} from '@mui/icons-material'

const StyledAppBar = styled(AppBar)<AppBarProps>`
  background-color: white;
  border-bottom: 1px solid ${({theme}) => theme.palette.grey.A200};
`

interface IProps {
    onOpen: Dispatch<SetStateAction<void>>
}

const Header = ({onOpen}: IProps): ReactElement => {

    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('md'))

    return (
        <header>
            <StyledAppBar elevation={0} position="relative">
                <Container sx={{
                    minHeight: '64px',
                    display: 'flex',
                    alignItems: 'center'
                }}>
                    {isMobile && <Box>
                        <Toolbar>
                            <IconButton onClick={() => {
                                onOpen()
                            }}>
                                <Menu/>
                            </IconButton>
                        </Toolbar>
                    </Box>}
                    <Box
                        sx={{
                            marginLeft: 'auto'
                        }}
                    >
                        <Avatar>AH</Avatar>
                    </Box>
                </Container>
            </StyledAppBar>
        </header>
    )
}


export default Header
