import {Alert, Box, Divider, IconButton, Paper, styled, Typography} from "@mui/material";
import {Close} from '@mui/icons-material'
import {Dispatch, forwardRef, ReactNode, SetStateAction} from "react";

const Wrapper = styled(Box)`
  width: 100%;
  height: 100%;
  background: white;

  ${({theme}) => theme.breakpoints.up('md')} {
    height: auto;
    width: auto;
    background: transparent;
  }
`

const Header = styled(Box)`
`

const Body = styled(Paper)`
  padding: ${({theme}) => theme.spacing(2)};

  ${({theme}) => theme.breakpoints.up('md')} {
    position: fixed;
    width: 100%;
    max-width: 500px;
    top: 25%;
    left: 50%;
    background: white;
    transform: translateX(-50%);
  }
`

interface IProps {
    children: ReactNode;
    title: string;
    onClose: Dispatch<SetStateAction<void>>;
}

const ModalBody = forwardRef<unknown, IProps>((props, ref) => {

    return (
        <Wrapper>
            <Body>
                <Header display="flex" alignItems="center" justifyContent="space-between">
                    <Typography fontWeight={500}>{props.title}</Typography>
                    <IconButton onClick={() => {
                        props.onClose()
                    }}>
                        <Close/>
                    </IconButton>
                </Header>
                <Box my={2}>
                <Divider/>
                </Box>
                {props.children}
            </Body>
        </Wrapper>
    )
})


export default ModalBody
