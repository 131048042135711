import axios from "axios";
import { Store } from "redux";
import { UserActionTypes } from "../redux/types/userTypes";

let store: Store | null = null;

export const injectStore = (_store: Store) => {
  store = _store;
};

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors.request.use((value) => {
  const token = window.localStorage.getItem("auth_token");
  if (token) {
    value.headers = {
      Authorization: `Bearer ${token}`,
    };
  }
  return value;
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (store && error.response.status === 401) {
      store.dispatch({
        type: UserActionTypes.USER_RESET,
      });
    }

    return Promise.reject(error);
  }
);

export default api;
