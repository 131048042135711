import {ReactElement, useEffect, useState} from "react";
import {Button, Modal} from "@mui/material";
import {Add} from '@mui/icons-material'

import NewProductModal from "../components/Modal/NewProductModal";
import useModalState from "../hooks/useModalState";
import ProductsTable from "../components/Table/ProductsTable";
import Heading from "../components/Heading/Heading";
import MainLayout from "../layout/MainLayout";
import {useDispatch, useSelector} from "react-redux";
import {getProductList, removeProduct} from "../redux/actions/productActions";
import {RootState} from "../redux/store";
import Loader from "../components/Loader/Loader";

const ProductsView = (): ReactElement => {

    const dispatch = useDispatch();
    const state = useSelector((state: RootState) => state.products)
    const {isOpen, onOpen, onClose} = useModalState()
    const [page, setPage] = useState<number>(0)

    const handleProductDelete = async (id: string) => {
        await dispatch(removeProduct(id))
        await dispatch(getProductList(page))
    }

    useEffect(() => {
        dispatch(getProductList(page))
    }, [dispatch, page])

    return (
        <MainLayout>
            <Modal open={isOpen} onClose={onClose}>
                <NewProductModal onClose={onClose}/>
            </Modal>
            <Heading title="Products">
                <Button variant="contained" startIcon={<Add/>} onClick={onOpen}>New Product</Button>
            </Heading>
            {state.products.isLoading && <Loader/>}
            {!state.products.isLoading && state.products.data && <ProductsTable data={state.products.data} page={page} onPageChange={setPage} onDelete={handleProductDelete}/>}
        </MainLayout>
    )
}

export default ProductsView
