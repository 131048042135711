import {Product, ProductsListResponse} from "../../interface/product";
import {ApiError} from "../../interface/error";

export enum ProductActionTypes {
    GET_PRODUCTS_LIST_REQUEST = 'GET_PRODUCTS_LIST_REQUEST',
    GET_PRODUCTS_LIST_SUCCESS = 'GET_PRODUCTS_LIST_SUCCESS',
    GET_PRODUCTS_LIST_FAILED = 'GET_PRODUCTS_LIST_FAILED',
    NEW_PRODUCT_REQUEST = 'NEW_PRODUCT_REQUEST',
    NEW_PRODUCT_SUCCESS = 'NEW_PRODUCT_SUCCESS',
    NEW_PRODUCT_FAILED = 'NEW_PRODUCT_FAILED',
    REMOVE_PRODUCT_REQUEST = 'REMOVE_PRODUCT_REQUEST',
    REMOVE_PRODUCT_SUCCESS = 'REMOVE_PRODUCT_SUCCESS',
    REMOVE_PRODUCT_FAILED = 'REMOVE_PRODUCT_FAILED',
    CLEAN_NEW_PRODUCT = 'CLEAN_NEW_PRODUCT'
}

export interface getProductListRequest {
    type: ProductActionTypes.GET_PRODUCTS_LIST_REQUEST
}

export interface getProductListSuccess {
    type: ProductActionTypes.GET_PRODUCTS_LIST_SUCCESS;
    payload: ProductsListResponse;
}

export interface getProductListFailed {
    type: ProductActionTypes.GET_PRODUCTS_LIST_FAILED;
    payload: ApiError;
}

export interface newProductRequest {
    type: ProductActionTypes.NEW_PRODUCT_REQUEST;
}

export interface newProductSuccess {
    type: ProductActionTypes.NEW_PRODUCT_SUCCESS;
    payload: Product;
}

export interface newProductFailed {
    type: ProductActionTypes.NEW_PRODUCT_FAILED;
    payload: ApiError;
}

export interface removeProductRequest {
    type: ProductActionTypes.REMOVE_PRODUCT_REQUEST,
}

export interface removeProductSuccess {
    type: ProductActionTypes.REMOVE_PRODUCT_SUCCESS,
}

export interface removeProductFailed {
    type: ProductActionTypes.REMOVE_PRODUCT_FAILED,
    payload: ApiError;
}

export interface cleanNewProduct {
    type: ProductActionTypes.CLEAN_NEW_PRODUCT
}


export type ProductActions =
    getProductListRequest
    | getProductListSuccess
    | getProductListFailed
    | newProductRequest
    | newProductSuccess
    | newProductFailed
    | removeProductRequest
    | removeProductSuccess
    | removeProductFailed
    | cleanNewProduct
