import {Dispatch} from "redux";
import {UserActions, UserActionTypes} from "../types/userTypes";
import api from "../../utils/api";
import {UserData} from "../../interface/user";
import {AxiosError} from "axios";
import {ApiError} from "../../interface/error";


export const userLogin = (email: string, password: string) => async (dispatch: Dispatch<UserActions>) => {
    dispatch({
        type: UserActionTypes.USER_LOGIN_REQUEST
    })

    try {
        const {data} = await api.post<UserData>('/auth/login', {email, password});

        window.localStorage.setItem('auth_token', data.token)

        dispatch({
            type: UserActionTypes.USER_LOGIN_SUCCESS,
            payload: data
        })
    } catch (err) {
        const e = err as AxiosError<ApiError>
        if (e.response) {
            dispatch({
                type: UserActionTypes.USER_LOGIN_FAILED,
                payload: {
                    code: e.response.data.code,
                    message: e.response.data.message
                }
            })
        }
    }
}

export const cleanUserError = () => (dispatch: Dispatch<UserActions>) => {
    dispatch({
        type: UserActionTypes.CLEAN_USER_ERROR
    })
}

export const userLogout = () => (dispatch: Dispatch<UserActions>) => {
    window.localStorage.removeItem('auth_token');
    dispatch({
        type: UserActionTypes.USER_LOGOUT
    })
}

export const getProfile = () => async (dispatch: Dispatch<UserActions>) => {
    try {
        await api.get('/auth/me')
    } catch (err) {
        console.log(err)
    }
}
